<template>
	<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
		<v-row dense class="pui-form-layout">
			<v-col cols="12" md="4">
				<pui-field-set highlighted>
					<v-row dense>
						<v-col cols="12" md="6">
							<pui-text-field
								:label="$t('form.operationsuser.usr')"
								v-model="model.usr"
								maxlength="100"
								toplabel
								required
								:disabled="formDisabled || !isCreatingElement"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-text-field
								:label="$t('form.operationsuser.name')"
								v-model="model.name"
								maxlength="200"
								toplabel
								required
								:disabled="formDisabled"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-text-field
								:label="$t('form.operationsuser.email')"
								v-model="model.email"
								maxlength="200"
								toplabel
								:disabled="formDisabled"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense v-if="isCreatingElement">
						<v-col cols="12" md="6">
							<input id="fakeusername" style="opacity: 0; position: absolute" type="text" name="fakeusernameremembered" />
							<pui-text-field
								v-if="!passHidden"
								:label="$t('form.operationsuser.password')"
								v-model="model.password"
								maxlength="100"
								toplabel
								required
								noeditable
								:append-icon="hidePass ? 'visibility_off' : 'visibility'"
								:append-icon-cb="() => (hidePass = !hidePass)"
								:type="hidePass ? 'text' : 'password'"
								:disabled="formDisabled"
								:autocomplete="'new-password'"
							></pui-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<pui-text-field
								v-if="!passHidden"
								:label="$t('form.operationsuser.repeatpassword')"
								v-model="model.repeatpassword"
								maxlength="100"
								toplabel
								noeditable
								:append-icon="hidePass ? 'visibility_off' : 'visibility'"
								:append-icon-cb="() => (hidePass = !hidePass)"
								:type="hidePass ? 'text' : 'password'"
								required
								:disabled="formDisabled"
								:autocomplete="'new-password'"
								:rules="repeatpasswordRules"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-select
								:label="$t('form.operationsuser.language')"
								v-model="model"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								modelName="puilanguage"
								:itemsToSelect="[{ isocode: model.language }]"
								:modelFormMapping="{ isocode: 'language' }"
								itemValue="isocode"
								itemText="name"
							></pui-select>
							<pui-select
								:label="$t('form.operationsuser.dateformat')"
								v-model="model"
								toplabel
								required
								:disabled="formDisabled"
								:items="[
									{ dateformat: 'yyyy/MM/dd' },
									{ dateformat: 'yyyy-MM-dd' },
									{ dateformat: 'dd/MM/yyyy' },
									{ dateformat: 'dd-MM-yyyy' }
								]"
								:itemsToSelect="[{ dateformat: model.dateformat }]"
								:modelFormMapping="{ dateformat: 'dateformat' }"
								itemValue="dateformat"
								itemText="dateformat"
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-checkbox
								:label="$t('form.puiuser.changepasswordnextlogin')"
								v-model="model.changepasswordnextlogin"
								true-value="1"
								false-value="0"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-checkbox
								:label="$t('form.operationsuser.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-date-field
								v-model="model.endsuscription"
								:label="$t('form.operationsuser.endsuscription')"
								:disabled="formDisabled && !hasManageUserSubscriptionFunctionality"
								toplabel
							></pui-date-field>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-col>
			<v-col cols="12" md="8">
				<pui-field-set :title="$t('form.operationsuser.info')">
					<v-row dense>
						<v-col cols="12">
							<pui-select
								:label="$t('form.operationsuser.companytype')"
								v-model="model"
								toplabel
								clearable
								:disabled="formDisabled"
								:itemsToSelect="itemsToSelectCompanyType"
								:modelFormMapping="{ companytypeid: 'companytype' }"
								itemValue="companytypeid"
								itemText="description"
								modelName="companytype"
								:fixedFilter="filterByCompanyType"
								reactive
								:key="companyTypeKey"
							></pui-select>
							<pui-select
								:label="$t('form.operationsuser.companyname')"
								v-model="model"
								toplabel
								clearable
								:disabled="formDisabled"
								:itemsToSelect="itemsToSelectCompany"
								:modelFormMapping="{ id: 'companyid' }"
								itemValue="id"
								itemText="name"
								modelName="company"
								:fixedFilter="filterByCompany"
								required
								reactive
								:key="companyTypeKey"
							></pui-select>
						</v-col>
						<v-col cols="12">
							<pui-select
								:label="$t('form.operationsuser.terminaloperatorname')"
								v-model="model.terminalOperatorsList"
								toplabel
								clearable
								:disabled="formDisabled"
								modelName="operatorcompany"
								:itemsToSelect="model.terminalOperatorsList"
								return-object
								itemValue="operatorcode"
								itemText="operatorname"
								multiple
								:order="{ descripcion: 'asc' }"
							></pui-select>
						</v-col>
						<v-col cols="12"> </v-col>
					</v-row>
				</pui-field-set>
				<pui-field-set :title="$t('form.operationsuser.portauthorities')">
					<pui-select
						v-model="model.portauthorities"
						toplabel
						clearable
						:disabled="formDisabled"
						:itemsToSelect="model.portauthorities"
						return-object
						:items="portAuthorities"
						itemValue="id"
						itemText="name"
						multiple
						:order="{ name: 'asc' }"
					></pui-select>
				</pui-field-set>
				<pui-field-set :title="$t('form.operationsuser.profiles')">
					<pui-multi-select
						itemValue="profile"
						itemText="name"
						v-model="model.profiles"
						:items="allProfiles"
						:itemsToSelect="model.profiles"
						:disabled="formDisabled"
						reactive
					></pui-multi-select>
				</pui-field-set>
				<pui-field-set :title="$t('form.operationsuser.ports')">
					<pui-multi-select
						itemValue="id"
						itemText="portname"
						v-model="model.ports"
						:items="getPortsToSelect"
						:itemsToSelect="model.ports"
						:disabled="formDisabled"
						:key="portsKey"
						reactive
					></pui-multi-select>
				</pui-field-set>
				<pui-field-set :title="$t('form.operationsuser.berths')">
					<pui-multi-select
						itemValue="id"
						itemText="name"
						v-model="model.berths"
						:items="berths"
						:itemsToSelect="model.berths"
						:disabled="formDisabled"
						reactive
					></pui-multi-select>
				</pui-field-set>
			</v-col>
		</v-row>
		<pui-form-footer v-if="!isModalDialog">
			<pui-form-footer-btns
				:formDisabled="formDisabled"
				:saveDisabled="saving"
				:saveAndNew="saveAndNew"
				:saveAndUpdate="saveAndUpdate"
				:save="save"
				:back="back"
			></pui-form-footer-btns>
		</pui-form-footer>
	</v-form>
	<pui-form-loading v-else></pui-form-loading>
</template>
<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
export default {
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'operationsuser',
			passHidden: false,
			hidePass: false,
			berths: [],
			ports: [],
			company: [],
			companyTypeKey: 0,
			portsKey: 0,
			portsauthoritiesKey: 0,
			portAuthorities: [],
			isAdmin: false
		};
	},
	computed: {
		repeatpasswordRules() {
			return [this.model.password === this.model.repeatpassword || this.$t('form.operationsuser.passwordMustMatch')];
		},
		allProfiles() {
			const allProfiles =
				this.$store.state.operationsuser && this.$store.state.operationsuser.puiprofile ? this.$store.state.operationsuser.puiprofile : [];
			const allCompanyProfiles = this.$store.state.operations.profilecompany ? this.$store.state.operations.profilecompany : [];
			const currentCompanyId = this.$store.getters.getCompanyId;
			const allUsercompanyProfilesStr = allCompanyProfiles
				? allCompanyProfiles.filter((porfcomp) => porfcomp.companyid == currentCompanyId)
				: [];
			const allUserCompanyProfilesList = allUsercompanyProfilesStr[0] ? allUsercompanyProfilesStr[0].profilelist.split(',') : [];
			const allUserCompanyProfilesOject = allProfiles ? allProfiles.filter((p) => allUserCompanyProfilesList.includes(p.profile)) : [];
			return allUserCompanyProfilesOject;
		},
		hasManageUserSubscriptionFunctionality() {
			return this.$store.getters.hasFunctionality('MANAGE_USER_SUBSCRIPTION');
		},
		availableCompany() {
			return this.$store.state.operationsuser && this.company ? this.company : [];
		},
		getPortsToSelect() {
			return this.ports;
		},
		filterByCompanyType() {
			const companyiduser = this.$store.state.session.properties.COMPANY.companytypeid;
			if (companyiduser != null && companyiduser != 10 && companyiduser != 7) {
				return {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'companytypeid', op: 'eq', data: companyiduser }]
				};
			}
		},
		filterByCompany() {
			if (this.$store.state.session.properties.COMPANY.companytypeid == 10) {
				if (this.model.companytype) {
					return {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'companytypeid', op: 'eq', data: this.model.companytype }]
					};
				} else {
					return null;
				}
			} else if (this.$store.state.session.properties.COMPANY.companytypeid == 7) {
				if (this.model.companytype) {
					return {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'companytypeid', op: 'eq', data: this.model.companytype },
							{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') }
						]
					};
				} else {
					return {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') }]
					};
				}
			} else if (this.$store.state.session.properties.COMPANY.companytypeid != null) {
				return {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'id', op: 'eq', data: this.$store.state.session.properties.COMPANY_ID }]
				};
			} else {
				return null;
			}
		},
		itemsToSelectCompany() {
			return [{ id: this.model.companyid }];
		},
		itemsToSelectCompanyType() {
			return [{ companytypeid: this.model.companytype }];
		}
	},
	watch: {
		'model.ports': {
			handler: function (selectedPports) {
				this.getAllBerthsByPorts();
			},
			deep: true
		},
		'model.companyid': {
			handler: function (newValue) {
				if (newValue !== null) {
					this.getCompany(newValue);
				} else {
					this.model.ports = [];
					this.berths = [];
					this.model.portauthorities = [];
					this.portsKey++;
					this.portsauthoritiesKey++;
				}
			},
			deep: true
		},
		'model.portauthorities': {
			handler: function (newValue) {
				if (newValue.length != 0) {
					this.ports = [];
					if (this.isAdmin) {
						this.$puiRequests.getRequest('/port/all', null, (response) => {
							this.ports = response.data;
							this.ports = this.ports.filter((objeto) => newValue.some((objeto2) => objeto2['id'] === objeto['portauthorityid']));
							this.portsKey++;
							this.portsauthoritiesKey++;
						});
					} else {
						this.ports = [];
						this.ports = JSON.parse(window.localStorage.getItem('pui9_session')).properties['PORTS'];
						this.ports = this.ports.filter((objeto) => newValue.some((objeto2) => objeto2['id'] === objeto['portauthorityid']));
						this.portsKey++;
						this.portsauthoritiesKey++;
					}
				} else {
					if (this.isAdmin) {
						this.$puiRequests.getRequest('/port/all', null, (response) => {
							this.ports = response.data;
							this.portsKey++;
							this.portsauthoritiesKey++;
						});
					} else {
						this.ports = JSON.parse(window.localStorage.getItem('pui9_session')).properties['PORTS'];
						this.portsKey++;
						this.portsauthoritiesKey++;
					}
				}
			},
			deep: true
		}
	},
	created() {
		this.$store.dispatch('puiMultiSelectGetAllItems', { model: 'operationsuser', requestModel: 'puiprofile', vue: this });
		this.$store.dispatch('getAllOperationsProfileCompany', { model: 'operations', requestModel: 'profilecompany', vue: this });
		this.isAdmin = JSON.parse(window.localStorage.getItem('pui9_session')).profiles.includes('ADMIN');
	},
	mounted() {
		this.filldata();
		if (!this.isCreatingElement) {
			this.passHidden = true;
		}
	},
	methods: {
		getAllPortAuthorities() {
			if (this.isAdmin) {
				const opts = {
					model: 'portauthority'
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					this.portAuthorities = response.data.data;
				});
			} else {
				this.portAuthorities = JSON.parse(window.localStorage.getItem('pui9_session')).properties['PORT_AUTHORITIES'];
			}
		},
		afterSave(userModel) {
			if (userModel.usr === this.$store.getters.getUser && userModel.language !== this.$store.getters.getUserLanguage) {
				const lang = userModel.language;
				window.localStorage.setItem('pui9_lang', lang);
				this.$store.commit('setUserLanguage', lang);
				this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
				this.$puiEvents.$emit('onPuiChangedLang', lang);
			}
		},
		getAllBerthsByPorts() {
			this.berths = [];
			if (this.model.ports.length != 0) {
				this.$puiRequests.getRequest('/berth/all', null, (response) => {
					this.berths = response.data.map((item) => {
						return {
							id: item.id,
							portid: item.portid,
							innerid: item.innerid,
							name: item.name,
							berthcode: item.berthcode,
							seaside: item.seaside,
							initbol: item.initbol,
							endbol: item.endbol,
							draught: item.draught,
							thegeom: item.thegeom ? item.thegeom.value : null
						};
					});
					this.berths = this.berths.filter((objeto) => this.model.ports.some((objeto2) => objeto2['portid'] == objeto['portid']));
					this.portsKey++;
					this.portsauthoritiesKey++;
				});
			}
		},
		calculatePorts() {
			if (this.isAdmin) {
				this.$puiRequests.getRequest('/port/all', null, (response) => {
					this.ports = response.data;
					this.portsKey++;
					this.portsauthoritiesKey++;
				});
			} else {
				this.ports = JSON.parse(window.localStorage.getItem('pui9_session')).properties['PORTS'];
				this.portsKey++;
				this.portsauthoritiesKey++;
			}
		},
		getCompany(companyId) {
			const opts = {
				model: 'company',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: companyId
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response && response.data.data) {
					this.model.companytype = response.data.data[0].companytypeid;
					this.companyTypeKey++;
					if (response.data.data[0].portauthorityid) {
						this.model.portauthorities = [];
						this.model.portauthorities = this.portAuthorities.filter(function (el) {
							return el.id == response.data.data[0].portauthorityid;
						});
						this.portsauthoritiesKey++;
					}
					if (response.data.data[0].portid) {
						this.model.ports = [];
						this.model.ports = this.ports.filter(function (el) {
							return el.id == response.data.data[0].portid;
						});
						this.portsKey++;
					}
				}
			});
		},
		filldata() {
			this.getAllPortAuthorities();
			this.calculatePorts();
			this.getAllBerthsByPorts();
		}
	}
};
</script>
